<script setup lang="ts">
import { Companies } from '@/models/main.model'
import { useCompanyStore } from '@/stores/company'
import { useMainStore } from '@/stores/main'
import { useVModel } from '@vueuse/core'
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import CompanyStatusTag from '../account/CompanyStatusTag.vue'

type Props = {
  selectedCompany: Companies | null
  openDirectly?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:visible', 'update:selectedCompany'])

const router = useRouter()
const mainStore = useMainStore()
const companyStore = useCompanyStore()
const selectedCompany = useVModel(props, 'selectedCompany', emit)

const dropdown = ref()
const companies = computed(() => mainStore.companies)

onMounted(() => {
  if (props.openDirectly) {
    setTimeout(() => {
      if (dropdown.value && dropdown.value.$el) {
        dropdown.value.$el.click()
      }
    }, 900)
  }
})
</script>
<template>
  <Dropdown
    ref="dropdown"
    v-if="companies.results && companies.results.length > 0"
    v-model="selectedCompany"
    :options="companies.results"
    :placeholder="
      !companyStore.company.id && router.currentRoute.value.path?.toString().includes('/entreprise/')
        ? 'Entreprise en cours de création...'
        : 'Veuillez sélectionner une entreprise'
    "
    scrollHeight="250px"
    filter
    :filterFields="['name', 'siret']"
    emptyFilterMessage="Aucun élément ne correspond à votre recherche"
    :loading="companies.loading"
    data-testid="dropdown-select-company"
    class="specific-max-width"
  >
    <template #value="slotProps">
      <span v-if="slotProps.value">
        <b>{{ slotProps.value.name }}</b>
        <span class="ml-2">({{ slotProps.value.siret }})</span>
      </span>
      <span v-else>{{ slotProps.placeholder }}</span>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center" v-if="slotProps.option">
        <b>{{ slotProps.option.name }}</b>
        <span class="ml-2">({{ slotProps.option.siret }})</span>
        <span class="ml-2">
          <CompanyStatusTag :status="slotProps.option.status" />
        </span>
      </div>
    </template>
  </Dropdown>
</template>
<style scoped>
.specific-max-width:not(.w-full) {
  max-width: 70vw;
}

@media screen and (max-width: 300px) {
  .specific-max-width:not(.w-full) {
    max-width: 44vw !important;
  }
}

@media screen and (min-width: 768px) {
  .specific-max-width:not(.w-full) {
    max-width: 45vw !important;
  }
}

@media screen and (min-width: 800px) {
  .specific-max-width:not(.w-full) {
    max-width: 47vw !important;
  }
}

@media screen and (min-width: 900px) {
  .specific-max-width:not(.w-full) {
    max-width: 40vw !important;
  }
}

@media screen and (min-width: 992px) {
  .specific-max-width:not(.w-full) {
    max-width: 26vw !important;
  }
}

@media screen and (min-width: 1100px) {
  .specific-max-width:not(.w-full) {
    max-width: 27vw !important;
  }
}

@media screen and (min-width: 1300px) {
  .specific-max-width:not(.w-full) {
    max-width: 29vw !important;
  }
}
</style>
