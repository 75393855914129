export enum FAQ_TAG {
  COMPANY = 'Entreprise',
  EMPLOYEE = 'Salarié',
  CONTRACT = 'Contrat',
  DECLARATION = 'DSN',
}

export type FAQ = {
  title: string
  tag?: FAQ_TAG
  answer: string
}

const content: FAQ[] = [
  {
    title: 'Pourquoi une nouvelle interface sur Teledsn ?',
    answer: `<p>La nouvelle interface Teledsn propose une interface plus moderne et plus aérée que l’ancienne interface,
    tout en reprenant les principes généraux de l’ancienne interface. Le but est de simplifier la navigation avec moins de clics,
    en présentant l’information utile de manière regroupée au bon endroit et au bon moment.</p>
    <p>C’est aussi l’opportunité pour nous de vous proposer de nouvelles fonctionnalités, qui viendront très prochainement enrichir l’application.</p>
    `,
  },
  {
    title: 'Comment accéder à la fiche entreprise ?',
    tag: FAQ_TAG.COMPANY,
    answer: `<p>Vous pouvez y accéder rapidement par le raccourci présent dans le bandeau de navigation,
    à côté de l’entreprise sélectionnée, en cliquant sur l’icône <i class="pi pi-pencil"></i>.</p>
    <img src="/images/faq/navigation_fiche_entreprise.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment accéder à la fiche paramétrage de mon entreprise ?',
    tag: FAQ_TAG.COMPANY,
    answer: `<p>Depuis la fiche entreprise, à l’étape 3 (Organismes sociaux),
    vous retrouverez votre fiche paramétrage au sein du bloc « ORGANISME COMPLÉMENTAIRE »,
    à condition d’avoir sélectionné l’organisme dans la liste précédente sur cette même étape.</p>
    <img src="/images/faq/fiche_parametrage_presente.jpg" class="w-full" />
    <img src="/images/faq/afficher_fiche_parametrage.jpg" class="w-full" />
    <p>Vous avez également la possibilité d’ajouter vous-même la fiche paramétrage de l’entreprise.</p>
    <img src="/images/faq/pas_de_fiche_parametrage.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment créer une nouvelle entreprise sur mon compte ?',
    tag: FAQ_TAG.COMPANY,
    answer: `<p>Vous pouvez y accéder rapidement par le raccourci présent dans le bandeau de navigation,
    à côté de l’entreprise sélectionnée, en cliquant sur l’icône <i class="pi pi-plus"></i> puis « Ajouter une entreprise »</p>
    <img src="/images/faq/navigation_ajouter_entreprise.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment accéder à la liste des mes salariés ?',
    tag: FAQ_TAG.EMPLOYEE,
    answer: `<p>Vous pouvez y accéder rapidement par le raccourci présent dans le bandeau de navigation, en cliquant sur l’icône 
    <i class="pi pi-users"></i></p>
    <img src="/images/faq/navigation_salaries.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment ajouter un nouveau salarié ?',
    tag: FAQ_TAG.EMPLOYEE,
    answer: `<p><u class="text-purple-500">Option 1 :</u> Vous pouvez y accéder rapidement par le raccourci présent
    dans le bandeau de navigation, à côté de l’entreprise sélectionnée, en cliquant sur l’icône
    <i class="pi pi-plus"></i> puis « Ajouter un salarié ».</p>
    <img src="/images/faq/navigation_ajouter_salarié.jpg" class="w-full" />
    <p><u class="text-purple-500">Option 2 :</u> Vous pouvez également ajouter un salarié depuis la liste des salariés
    accessible avec le bouton <i class="pi pi-users"></i> du bandeau de navigation, puis en cliquant sur sur le bouton « Ajouter un salarié ».</p>
    <img src="/images/faq/liste_salaries_ajouter_salarie.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment accéder aux contrats d’un salarié ?',
    tag: FAQ_TAG.CONTRACT,
    answer: `<p><u class="text-purple-500">Option 1 :</u> Depuis la liste de vos salariés, pour chaque ligne, vous avez la possibilité d’afficher
    leurs contrats via le bouton « Contrats <i class="pi pi-eye"></i> ».</p>
    <img src="/images/faq/contrats_salaries.jpg" class="w-full" />
    <p><u class="text-purple-500">Option 2 :</u> Depuis la fiche d’un salarié, retrouvez ses contrats à gauche de votre écran.</p>
    <img src="/images/faq/contrats_dans_fiche_salaries.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment ajouter un nouveau contrat ?',
    tag: FAQ_TAG.CONTRACT,
    answer: `<p><u class="text-purple-500">Option 1 :</u> Un raccourci est à disposition dans le bandeau de navigation, en cliquant sur l’icône
    <i class="pi pi-plus"></i> puis « Ajouter un contrat ».</p>
    <img src="/images/faq/navigation_ajouter_contrat.jpg" class="w-full" />
    <p>Une petite fenêtre apparaît ensuite afin de sélectionner le salarié concerné.</p>
    <img src="/images/faq/modal_ajouter_contrat_steps.jpg" class="w-full" />
    <p><u class="text-purple-500">Option 2 :</u> Depuis la liste des salariés, via le bouton « Ajouter un contrat » dans les contrats d’un salarié.</p>
    <img src="/images/faq/ajouter_contrat_fiche_salarie.jpg" class="w-full" />
    <p><u class="text-purple-500">Option 3 :</u> Depuis la fiche d'un salarié, via le bouton « Ajouter un contrat » accessible dans la partie de gauche.</p>
    <img src="/images/faq/fiche_salarie_ajouter_contrat.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment créer une nouvelle déclaration mensuelle ?',
    tag: FAQ_TAG.DECLARATION,
    answer: `<p>La création d’une DSN mensuelle s’effectue depuis l'onglet « Mes déclarations » du tableau de bord en cliquant sur le bouton « Effectuer une DSN mensuelle »</p>
    <img src="/images/guide/tableau_de_bord_creer_dsn.jpg" class="w-full" />
    <p>Une fenêtre s’ouvre afin de sélectionner la période que vous souhaitez déclarer, en général le mois précédent.</p>
    <img src="/images/guide/modal_creer_dsn_steps.jpg" class="w-full" />`,
  },
  /*{
    title: 'Comment remplir une DSN individuelle ?',
    tag: FAQ_TAG.DECLARATION,
    answer: ``,
  },
  {
    title: 'Comment remplir la DSN entreprise ?',
    tag: FAQ_TAG.DECLARATION,
    answer: ``,
  },*/
  {
    title: "Comment importer un fichier d'initialisation des Taux PAS récupéré depuis TOPAZE ?",
    tag: FAQ_TAG.EMPLOYEE,
    answer: `<p>Depuis la liste des salariés, à côté du bouton « Ajouter un salarié »,
    vous retrouvez les options liées au téléchargement/import des fichiers CRM taux PAS.</p>
    <img src="/images/faq/import_topaze.jpg" class="w-full" />
    `,
  },
  {
    title: 'Comment activer mon abonnement, quels sont les moyens de paiement acceptés ?',
    tag: FAQ_TAG.COMPANY,
    answer: `<p>Vous pouvez créer et remplir vos DSN sans abonnement, par la suite, lorsque vous souhaiterez les envoyer,
    l’abonnement sera nécessaire. Une redirection vers la page d'abonnement sera proposée dans la fenêtre nommée « Synthèse avant envoi ».</p>
    <img src="/images/faq/synthese_dsn_abonnement.jpg" class="w-full" />
    <p>Une fois arrivé sur la page d’abonnement, voici les éléments que vous retrouverez :</p>
    <img src="/images/faq/page_abonnement.jpg" class="w-full" />
    <ol>
      <li>Entreprise concernée</li>
      <li>Montant et durée de l’abonnement</li>
      <li>Choix du mode de paiement (carte bancaire, virement ou chèque)<br />
      Vous trouverez les modalités pour chacun de ces modes, directement sur cette même page
      </li>
      <li>À noter que si une DSN est sélectionnée, elle sera automatiquement envoyée une fois le paiement effectué</li>
    </ol>
    `,
  },
]

export default content
