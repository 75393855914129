import { defineStore } from 'pinia'
import { teledsnAPI } from '@/services/teledsn-api'
import {
  PAYMENT_METHOD,
  SOCIAL_ORGANIZATIONS,
  GeneralInformationsPayload,
  ContactPayload,
  SocialOrganizationPayload,
  TaxationPayload,
  WorkRateAccidentPayload,
  Company,
  Options,
  SocialOrganizationAPI,
} from '@/models/company.model'
import { Helper } from '@/models/company.helper'
import { useAuthStore } from './auth'
import { useMainStore } from './main'
import { router } from '@/routes'
import { Router } from '@/models/router.model'
import { dateToFRFormat } from '@/utils/index'
import FileSaver from 'file-saver'

type State = {
  options: Options
  company: Company
  loading: boolean
  subLoading: boolean
  selectedCompanyBeforeCreation: number | null
  form: { isSubmitted: boolean; haveEmptyFields: boolean }
  showSuggestionsModal: boolean
}

export const useCompanyStore = defineStore('companyStore', {
  state: (): State => ({
    options: {
      apenCodes: [],
      regimes: [
        { value: 'Régime Général CNAM - CNAV', key: '01' },
        { value: 'Régime Alsace-Moselle CNAM - CNAV', key: '02' },
      ],
      opcoRefs: [],
      collectiveConventions: [],
      civilities: [
        { key: '02', value: 'Madame' },
        { key: '01', value: 'Monsieur' },
      ],
      organizations: [
        {
          code: SOCIAL_ORGANIZATIONS.URSSAF,
          libelle: 'URSSAF',
          showPaymentMethod: true,
          frequency: null,
          paymentMethod: null,
          paymentMethodOptions: Helper.createPaymentMethodList(SOCIAL_ORGANIZATIONS.URSSAF),
        },
        {
          code: SOCIAL_ORGANIZATIONS.AGIRC_ARRCO,
          libelle: 'AGIRC-ARRCO',
          showFrequency: true,
          frequency: null,
          showPaymentMethod: true,
          paymentMethod: null,
          paymentMethodOptions: Helper.createPaymentMethodList(SOCIAL_ORGANIZATIONS.AGIRC_ARRCO),
        },
        {
          code: SOCIAL_ORGANIZATIONS.DGFIP,
          libelle: 'DGFIP',
          showPaymentMethod: true,
          paymentMethod: PAYMENT_METHOD.SEPA,
          frequency: null,
          paymentMethodOptions: Helper.createPaymentMethodList(SOCIAL_ORGANIZATIONS.DGFIP),
        },
        {
          code: SOCIAL_ORGANIZATIONS.FRANCE_TRAVAIL,
          libelle: 'FRANCE TRAVAIL',
          frequency: null,
          paymentMethod: null,
          paymentMethodOptions: Helper.createPaymentMethodList(SOCIAL_ORGANIZATIONS.FRANCE_TRAVAIL),
        },
        {
          code: SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE,
          libelle: 'ORGANISME COMPLEMENTAIRE',
          showPaymentMethod: true,
          frequency: null,
          paymentMethod: null,
          paymentMethodOptions: Helper.createPaymentMethodList(SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE),
        },
        {
          code: SOCIAL_ORGANIZATIONS.CIBTP,
          libelle: 'CIBTP',
          showOther: true,
          frequency: null,
          paymentMethod: null,
          other: null,
        },
        {
          code: SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS,
          libelle: 'CONGES SPECTACLES - AUDIENS',
          showFrequency: true,
          frequency: null,
          showPaymentMethod: true,
          paymentMethod: null,
          paymentMethodOptions: Helper.createPaymentMethodList(SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS),
        },
      ],
      cibtpReferences: [],
      complementaryPensionReferences: [],
      riskCodes: [],
      taxations: [],
      eligiblePeriods: [],
    },
    company: {
      id: null,
      generalInformations: {
        companyInfos: {
          siren: '',
          nic: '',
          companyName: '',
          selectedApenCode: null,
          selectedCollectiveConvention: null,
          selectedRegimeApplied: null,
          selectedOpcoRef: '',
          payrollDeferral: false,
        },
        locality: {
          zipCode: '',
          selectedCity: null,
          country: 'FRANCE',
          isForeign: false,
          address: {
            street: '',
            addressSupplement: '',
            namedPlace: '',
          },
        },
      },
      contact: {
        name: '',
        civility: '',
        email: '',
        phoneNumber: '',
      },
      socialOrganizations: {
        selected: [],
        rib: {},
      },
      workAccidents: {
        riskCode: null,
        effectiveAccidentRate: 0,
        additionalAccidentRate: null,
      },
      taxation: {
        yearEndClosing: new Date('2900-12-31'),
        selectedTaxations: [],
      },
      notifications: [],
      status: false,
    },
    loading: false,
    subLoading: false,
    selectedCompanyBeforeCreation: null,
    form: { isSubmitted: false, haveEmptyFields: false },
    showSuggestionsModal: false,
  }),
  getters: {
    getSocialOrganizationAndCompleteIt() {
      return (socialOrganization: SOCIAL_ORGANIZATIONS, organization?: SocialOrganizationAPI) =>
        Helper.completeOrganization(
          this.options.organizations.find((organization) => organization.code === socialOrganization)!,
          organization,
        )
    },
    isFirstStep() {
      return router.currentRoute.value.meta.isFirstStep
    },
    isLastStep() {
      return router.currentRoute.value.meta.isLastStep
    },
    complementaryOrganismsAvailable() {
      return () =>
        this.company.socialOrganizations.selected.find(
          (org) => org.code === SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE,
        )
    },
    getFullLabelForSelectedConvention() {
      return () =>
        this.options.collectiveConventions.find(
          (convention) => convention.key === this.company.generalInformations.companyInfos.selectedCollectiveConvention,
        )?.value
    },
    isCiBTP() {
      return () =>
        this.company.socialOrganizations.selected.find(
          (organization) => organization.code === SOCIAL_ORGANIZATIONS.CIBTP,
        )
    },
    getCompanyComplementaryPensions() {
      return () =>
        this.company.socialOrganizations.selected.find(
          (organization) => organization.code === SOCIAL_ORGANIZATIONS.AGIRC_ARRCO,
        )?.other
    },
    isSubscriptionActive() {
      return () =>
        useMainStore().companies.results.find((company) => company.id === this.company.id)?.isSubscriptionActive
    },
  },
  actions: {
    previousStep() {
      let previousStep = ''

      switch (router.currentRoute.value.name) {
        case Router.STEP_GENERAL_INFORMATIONS:
          previousStep = Router.MY_ACCOUNT + Router.MY_COMPANIES
          break
        case Router.STEP_CONTACT:
          previousStep = Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS
          break
        case Router.STEP_SOCIAL_ORGANIZATIONS:
          previousStep = Router.COMPANY + Router.STEP_CONTACT
          break
        case Router.STEP_TAXATION:
          previousStep = Router.COMPANY + Router.STEP_SOCIAL_ORGANIZATIONS
          break
        case Router.STEP_WORK_ACCIDENT:
          previousStep = Router.COMPANY + Router.STEP_TAXATION
          break
        default:
          previousStep = '/'
      }

      router.push(previousStep)
    },
    async nextStep() {
      const mainStore = useMainStore()
      mainStore.resetServerMessages()
      let nextStep = ''
      this.form.isSubmitted = true

      if (!this.form.haveEmptyFields) {
        switch (router.currentRoute.value.name) {
          case Router.STEP_GENERAL_INFORMATIONS:
            nextStep = Router.COMPANY + Router.STEP_CONTACT
            if (this.company.id) await this.modififyGeneralInformations()
            else await this.createNewCompany()
            break
          case Router.STEP_CONTACT:
            nextStep = Router.COMPANY + Router.STEP_SOCIAL_ORGANIZATIONS
            await this.modifyCompanyContact()
            break
          case Router.STEP_SOCIAL_ORGANIZATIONS:
            nextStep = Router.COMPANY + Router.STEP_TAXATION
            await this.modifyCompanySocialOrganization()
            break
          case Router.STEP_TAXATION:
            nextStep = Router.COMPANY + Router.STEP_WORK_ACCIDENT
            await this.modifyTaxationDetails()
            break
          case Router.STEP_WORK_ACCIDENT:
            await this.modifyWorkRateAccidentDetails()
            break
          default:
            break
        }
      } else {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Un ou plusieurs champs sont manquants',
          keepAfterDisplay: true,
        })
      }

      if (!mainStore.serverHaveReturnedError) {
        mainStore.formHaveBeenModified = false
        if (mainStore.haveCanceledRoute) await router.push(mainStore.canceledRoute)
        else if (nextStep) await router.push(nextStep)
        else if (this.isLastStep) {
          await this.getCompanyDetails()
          this.showSuggestionsModal = true
        }
      }
    },
    setDefaultSocialOrganizations(defaultOrganizations: SOCIAL_ORGANIZATIONS[]) {
      this.company.socialOrganizations.selected = []
      defaultOrganizations.forEach((defaultOrganization) => {
        const completedOrganization = this.getSocialOrganizationAndCompleteIt(defaultOrganization)
        if (completedOrganization) this.company.socialOrganizations.selected.push(completedOrganization)
      })
    },
    async getApenCodes() {
      try {
        const response = await teledsnAPI.getApenCodes()
        this.options.apenCodes = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des codes APEN.',
        })
      }
    },
    async getCollectiveConventions() {
      try {
        const response = await teledsnAPI.getCollectiveConventions()
        this.options.collectiveConventions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des conventions collectives.',
        })
      }
    },
    async getOpcos() {
      try {
        const response = await teledsnAPI.getOpcos()
        this.options.opcoRefs = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des OPCO.',
        })
      }
    },
    async getPreExistingFields(siret: string) {
      try {
        const response = await teledsnAPI.getPreExistingFields(siret)
        this.company.generalInformations.companyInfos.companyName = response.raisonSociale
        this.company.generalInformations.locality.address.street = response.adresse1
        this.company.generalInformations.locality.zipCode = response.codePostal
        const apenCode = this.options.apenCodes?.find((item) => item.key === response.codeApen)
        if (apenCode) this.company.generalInformations.companyInfos.selectedApenCode = apenCode.key
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors du remplissage automatique des champs en fonction du numéro de SIRET.',
        })
      }
    },
    async getCibtpReferences() {
      try {
        const response = await teledsnAPI.getCibtpReferences()
        this.options.cibtpReferences = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des références CIBTP.',
        })
      }
    },
    async getTaxationReferences() {
      try {
        const response = await teledsnAPI.getTaxationReferences()
        this.options.taxations = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des références fiscales.',
        })
      }
    },
    async getComplementaryPensionReferences() {
      try {
        const response = await teledsnAPI.getComplementaryPensionReferences()
        this.options.complementaryPensionReferences = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des références de complémentaires retraite.',
        })
      }
    },
    async getWorkAccidentCodeReferences(regime: string) {
      try {
        const response = await teledsnAPI.getWorkAccidentCodeReferences(regime)
        this.options.riskCodes = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des références de complémentaires retraite.',
        })
      }
    },
    async createNewCompany() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const siret = this.company.generalInformations.companyInfos.siren.concat(
          this.company.generalInformations.companyInfos.nic,
        )

        const payload: GeneralInformationsPayload = {
          raisonSociale: this.company.generalInformations.companyInfos.companyName,
          siret,
          cp: this.company.generalInformations.locality.zipCode,
          numeroVoie: this.company.generalInformations.locality.address.street,
          localite: this.company.generalInformations.locality.selectedCity!.localite,
          codePays:
            this.company.generalInformations.locality.country === 'FRANCE'
              ? ''
              : this.company.generalInformations.locality.country,
          codeApen: this.company.generalInformations.companyInfos.selectedApenCode,
          complementAdresse: this.company.generalInformations.locality.address.addressSupplement,
          complementAdresse2: this.company.generalInformations.locality.address.namedPlace,
          firmeEtrangere: this.company.generalInformations.locality.isForeign,
          ccn: this.company.generalInformations.companyInfos.selectedCollectiveConvention,
          regime: this.company.generalInformations.companyInfos.selectedRegimeApplied,
          codeOpco: this.company.generalInformations.companyInfos.selectedOpcoRef,
          decalagePaie: this.company.generalInformations.companyInfos.payrollDeferral,
        }

        const response = await teledsnAPI.createNewCompany(payload)
        await useAuthStore().getCookieInfos()
        this.company.id = response.id
        mainStore.serverResponseStatut.successMessage = `Votre entreprise a bien été créée.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async modifyCompanyContact() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: ContactPayload = {
          codeCivilite: this.company.contact.civility,
          nomPrenom: this.company.contact.name,
          email: this.company.contact.email,
          tel: this.company.contact.phoneNumber,
        }

        await teledsnAPI.modifyCompanyContact(this.company.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Les informations de contact ont bien été enregistrées`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async modifyCompanySocialOrganization() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: SocialOrganizationPayload = {
          organismes: Helper.transformSelectedSocialOrganizationForAPI(this.company.socialOrganizations.selected),
          rib: {
            iban: this.company.socialOrganizations.rib.iban
              ? this.company.socialOrganizations.rib.iban.replaceAll(' ', '')
              : null,
            bic: this.company.socialOrganizations.rib.bic,
          },
        }

        await teledsnAPI.modifyCompanySocialOrganization(this.company.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Vos organismes sociaux ont bien été enregistrées`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async modifyTaxationDetails() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: TaxationPayload = {
          taxationsSelectionnees: this.company.taxation.selectedTaxations,
          dateCloture: dateToFRFormat(this.company.taxation.yearEndClosing).substring(0, 5),
        }

        await teledsnAPI.modifyTaxationDetails(this.company.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Vos informations fiscales ont bien été enregistrées`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async modifyWorkRateAccidentDetails() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: WorkRateAccidentPayload = {
          tauxAt: this.company.workAccidents.effectiveAccidentRate,
          codeRisque: this.company.workAccidents.riskCode!,
          tauxAtSupplementaire: this.company.workAccidents.additionalAccidentRate,
        }

        await teledsnAPI.modifyAccidentWorkRateDetails(this.company.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Vos taux d'accidents du travail ont bien été enregistrées`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async getCompanyDetails() {
      const mainStore = useMainStore()
      try {
        this.loading = true

        const response = await teledsnAPI.getCompanyDetails(this.company.id!)
        this.company = Helper.storingDataFromAPI(response)
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
    async modififyGeneralInformations() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const siret = this.company.generalInformations.companyInfos.siren.concat(
          this.company.generalInformations.companyInfos.nic,
        )

        const payload: GeneralInformationsPayload = {
          raisonSociale: this.company.generalInformations.companyInfos.companyName,
          siret,
          cp: this.company.generalInformations.locality.zipCode,
          numeroVoie: this.company.generalInformations.locality.address.street,
          localite: this.company.generalInformations.locality.selectedCity!.localite,
          codePays:
            this.company.generalInformations.locality.country === 'FRANCE'
              ? ''
              : this.company.generalInformations.locality.country,
          codeApen: this.company.generalInformations.companyInfos.selectedApenCode,
          complementAdresse: this.company.generalInformations.locality.address.addressSupplement,
          complementAdresse2: this.company.generalInformations.locality.address.namedPlace,
          firmeEtrangere: this.company.generalInformations.locality.isForeign,
          ccn: this.company.generalInformations.companyInfos.selectedCollectiveConvention,
          regime: this.company.generalInformations.companyInfos.selectedRegimeApplied,
          codeOpco: this.company.generalInformations.companyInfos.selectedOpcoRef,
          decalagePaie: this.company.generalInformations.companyInfos.payrollDeferral,
        }

        await teledsnAPI.modifyCompanyGeneralInformation(this.company.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Vos informations générales ont bien été enregistrés`
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.subLoading = false
      }
    },
    async getSocialOrganizationConfigurationSheet() {
      try {
        const response = await teledsnAPI.getSocialOrganizationConfigurationSheet(this.company.id!)
        this.company.socialOrganizations.configurationSheet = response
      } catch (error) {
        useMainStore().setServerResponseFromError(error)
      }
    },
    async transmitSocialOrganizationConfigurationSheet(sheet: File) {
      const mainStore = useMainStore()
      try {
        this.subLoading = true
        await teledsnAPI.transmitSocialOrganizationConfigurationSheet(this.company.id!, sheet)
        mainStore.serverResponseStatut.successMessage = `Votre fiche paramétrage a bien été transmise`
        this.getSocialOrganizationConfigurationSheet()
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.subLoading = false
      }
    },
    async uploadCrmFile(file: File) {
      const mainStore = useMainStore()
      try {
        await teledsnAPI.uploadCrmFile(file, this.company.id!)
        mainStore.serverResponseStatut.successMessage = 'Le CRM a bien été importé et traité'
      } catch (error) {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de l'importation du CRM.",
        })
      }
    },
    async getLastSentCrmFile() {
      const mainStore = useMainStore()
      try {
        const response = await teledsnAPI.getLastSentCrmFile(this.company.id!)
        FileSaver.saveAs(
          new Blob([response]),
          'teledsn-' + this.company.generalInformations.companyInfos.companyName + '_CRM_taux_pas.xml',
        )
      } catch (error) {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération du dernier CRM envoyé.',
        })
      }
    },
    async getEligiblePeriods() {
      const mainStore = useMainStore()
      try {
        if (this.company.id) {
          const response = await teledsnAPI.getEligiblePeriods(this.company.id)
          //Filtrer les périodes elibigibles par rapport aux déclarations existantes
          this.options.eligiblePeriods = response.filter(
            (eligiblePeriod) =>
              !mainStore.dashboard.declarations.results.find(
                (declaration) => declaration.rawPeriod === eligiblePeriod.period,
              ),
          )
        }
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      }
    },
  },
  persist: {
    afterRestore: (ctx) => {
      if (ctx.pinia.state?.value?.companyStore?.company?.taxation?.yearEndClosing) {
        ctx.pinia.state.value.companyStore.company.taxation.yearEndClosing = new Date(
          ctx.pinia.state.value.companyStore.company.taxation.yearEndClosing,
        )
      }
    },
  },
})
