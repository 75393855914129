import { defineStore } from 'pinia'

type State = {
  rowsPerPageOptions: number[]
  rowsPerPage: number
  isDarkMode: boolean
}

export const useUtilsStore = defineStore('useUtilsStore', {
  state: (): State => ({
    rowsPerPageOptions: [5, 8, 10, 15, 20, 25, 30, 50, 75, 100],
    rowsPerPage: 10,
    isDarkMode: false,
  }),
  getters: {},
  actions: {
    setRows(rowPerPage: number) {
      this.rowsPerPage = rowPerPage
    },
  },
  persist: true,
})
