<script setup lang="ts">
type EmitsType = {
  (e: 'scrolling', value: Event): void
}

const emit = defineEmits<EmitsType>()
</script>
<template>
  <div @scroll="emit('scrolling', $event)" class="grid grid-nogutter row-gap-4 special-height">
    <slot />
  </div>
</template>
<style scoped>
.special-height {
  overflow: auto;
  scroll-behavior: smooth;
}

@media screen and (min-height: 500px) {
  .special-height {
    height: 32vh;
    margin-bottom: 4rem;
  }
}

@media screen and (min-height: 550px) {
  .special-height {
    height: 38vh;
    margin-bottom: 4rem;
  }
}

@media screen and (min-height: 600px) {
  .special-height {
    height: 45vh;
  }
}

@media screen and (min-height: 700px) {
  .special-height {
    height: 52vh;
  }
}

@media screen and (min-height: 750px) {
  .special-height {
    height: 53vh;
  }
}

@media screen and (min-height: 800px) {
  .special-height {
    height: 57vh;
  }
}

@media screen and (min-height: 850px) {
  .special-height {
    height: 59vh;
  }
}

@media screen and (min-height: 900px) {
  .special-height {
    height: 62vh;
  }
}

@media screen and (min-height: 1000px) {
  .special-height {
    height: 66vh;
  }
}

@media screen and (min-height: 1100px) {
  .special-height {
    height: 69vh;
  }
}

@media screen and (min-height: 1200px) {
  .special-height {
    height: 71vh;
  }
}

@media screen and (min-height: 1300px) {
  .special-height {
    height: 74vh;
  }
}

@media screen and (min-height: 1400px) {
  .special-height {
    height: 77vh;
  }
}

@media screen and (min-height: 1600px) {
  .special-height {
    height: 79vh;
  }
}

@media screen and (min-height: 1800px) {
  .special-height {
    height: 82vh;
  }
}
</style>
