import { createApp } from 'vue'
import App from '@/App.vue'
import { router } from '@/routes'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import DOMPurify from 'dompurify'
import VueGuidedTour from 'vue-guided-tour'
import 'vue-guided-tour/style.css'

import PrimeVue from 'primevue/config'

import Toolbar from 'primevue/toolbar'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Menu from 'primevue/menu'
import Dialog from 'primevue/dialog'
import InlineMessage from 'primevue/inlinemessage'
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import TabMenu from 'primevue/tabmenu'
import Password from 'primevue/password'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Message from 'primevue/message'
import Steps from 'primevue/steps'
import OverlayPanel from 'primevue/overlaypanel'
import AutoComplete from 'primevue/autocomplete'
import RadioButton from 'primevue/radiobutton'
import MultiSelect from 'primevue/multiselect'
import Card from 'primevue/card'
import FileUpload from 'primevue/fileupload'
import Checkbox from 'primevue/checkbox'
import Panel from 'primevue/panel'
import InputMask from 'primevue/inputmask'
//import InputNumber from 'primevue/inputnumber'
import InputNumberCustom from '@/components/commons/InputNumberCustom.vue'
import Calendar from 'primevue/calendar'
import Badge from 'primevue/badge'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import ProgressSpinner from 'primevue/progressspinner'
import ToggleButton from 'primevue/togglebutton'
import TextArea from 'primevue/textarea'
import PanelMenu from 'primevue/panelmenu'
import ScrollPanel from 'primevue/scrollpanel'
import FloatLabel from 'primevue/floatlabel'
import Chip from 'primevue/chip'
import Skeleton from 'primevue/skeleton'
import BadgeDirective from 'primevue/badgedirective'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tag from 'primevue/tag'
import SpeedDial from 'primevue/speeddial'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import * as Sentry from '@sentry/vue'
import VueTippy from 'vue-tippy'
import { FocusTrap } from 'focus-trap-vue'

import '@/utils/polyfills'

import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'animate.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/animations/shift-away.css'
import '@/assets/css/theme.css'
import '@/assets/css/icon.css'
import '@/assets/css/style.css'
import { createGtm } from '@gtm-support/vue-gtm'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
  .use(PrimeVue, {
    ripple: true,
    locale: {
      firstDayOfWeek: 1,
      dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      monthNamesShort: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
    },
  })
  .use(pinia)
  .use(router)
  .use(ToastService)
  .use(ConfirmationService)
  .use(VueGuidedTour)
  .use(VueTippy, {
    directive: 'tippy',
    defaultProps: {
      arrow: true,
      hideOnClick: false,
      delay: 500,
      placement: 'auto-end',
      animation: 'shift-away',
      allowHTML: true,
    },
  })
  .component('Toolbar', Toolbar)
  .component('InputText', InputText)
  .component('InputSwitch', InputSwitch)
  .component('Dropdown', Dropdown)
  .component('DataTable', DataTable)
  .component('Column', Column)
  .component('Menu', Menu)
  .component('Dialog', Dialog)
  .component('Button', Button)
  .component('SplitButton', SplitButton)
  .component('InlineMessage', InlineMessage)
  .component('TabMenu', TabMenu)
  .component('Password', Password)
  .component('Toast', Toast)
  .component('Message', Message)
  .component('Steps', Steps)
  .component('OverlayPanel', OverlayPanel)
  .component('AutoComplete', AutoComplete)
  .component('RadioButton', RadioButton)
  .component('InputMask', InputMask)
  .component('MultiSelect', MultiSelect)
  .component('Card', Card)
  .component('Calendar', Calendar)
  .component('FileUpload', FileUpload)
  .component('Checkbox', Checkbox)
  .component('InputNumber', InputNumberCustom)
  .component('Panel', Panel)
  .component('Badge', Badge)
  .component('ConfirmPopup', ConfirmPopup)
  .component('TextAreaPrimevue', TextArea)
  .component('ProgressSpinner', ProgressSpinner)
  .component('ToggleButton', ToggleButton)
  .component('PanelMenu', PanelMenu)
  .component('ScrollPanel', ScrollPanel)
  .component('FloatLabel', FloatLabel)
  .component('Chip', Chip)
  .component('Skeleton', Skeleton)
  .component('Tag', Tag)
  .component('TabView', TabView)
  .component('TabPanel', TabPanel)
  .component('SpeedDial', SpeedDial)
  .component('FocusTrap', FocusTrap)
  .component('IconField', IconField)
  .component('InputIcon', InputIcon)
  .directive('badge', BadgeDirective)
  .directive('sane-html', (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value)
  })

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://adc5ca9325630075d095f05fb62b25d0@o4505085270753280.ingest.us.sentry.io/4507904749273088',
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

if (import.meta.env.MODE === 'stage') {
  app.use(
    createGtm({
      id: 'GTM-MWBGQVD6',
      vueRouter: router,
    }),
  )
}

app.mount('#app')
