<script setup lang="ts">
import { RouterView } from 'vue-router'
import HeaderSection from '@/components/commons/HeaderSection.vue'
import { useAuthStore } from '@/stores/auth'
import { computed, onMounted, ref, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useMainStore } from '@/stores/main'
import { usePrimeVue } from 'primevue/config'
import { useCompanyStore } from '@/stores/company'
import OnBoardingTour from './components/commons/OnBoardingTour.vue'
import { useDeclarationStore } from './stores/declaration'
import { useUtilsStore } from './stores/utils'

const toast = useToast()
const authStore = useAuthStore()
const mainStore = useMainStore()
const companyStore = useCompanyStore()
const declarationStore = useDeclarationStore()
const utilsStore = useUtilsStore()
const primevue = usePrimeVue()

const isMounted = ref(false)
const loading = computed(() => mainStore.loading)
const isDarkMode = computed(() => utilsStore.isDarkMode)
const showWarningSessionExpired = computed(() => authStore.showWarningSessionExpired)
const displayOverlay = computed(() => declarationStore.individualDeclaration.focusPanel)

onMounted(() => {
  authStore.showWarningSessionExpired = false
  mainStore.formHaveBeenModified = false
  mainStore.hasRecentlySentDeclaration = false
  declarationStore.individualDeclaration.focusPanel = null
  mainStore.resetServerMessages()

  authStore.getCookieInfos().then(() => {
    const companyId = new URL(location.toString()).searchParams.get('eid')
    if (companyId && mainStore.getCompanyById(Number(companyId))) {
      companyStore.company.id = Number(companyId)
    }

    if (isDarkMode.value) {
      primevue.changeTheme('lara-light-cyan', 'lara-dark-cyan', 'theme-link')
    } else {
      primevue.changeTheme('lara-dark-cyan', 'lara-light-cyan', 'theme-link')
    }

    mainStore.displayOnBoardingTour().finally(() => {
      mainStore.loading = false
      isMounted.value = true
    })
  })
})

watch(
  mainStore.serverResponseStatut,
  () => {
    if (mainStore.serverResponseStatut.errorMessages) {
      for (const errorMessage of mainStore.serverResponseStatut.errorMessages) {
        if (!errorMessage.alreadyDisplayed) {
          toast.add({
            severity: errorMessage.blocking ? 'error' : 'warn',
            summary: errorMessage.blocking ? 'Erreur' : 'Avertissement',
            detail: errorMessage.value,
            life: 7000,
          })

          errorMessage.alreadyDisplayed = true
        }
      }
    }

    if (mainStore.serverResponseStatut.successMessage) {
      toast.add({
        severity: 'success',
        summary: 'Confirmation',
        detail: mainStore.serverResponseStatut.successMessage,
        life: 5000,
      })
      mainStore.serverResponseStatut.successMessage = ''
    }

    if (mainStore.serverResponseStatut.infoMessage) {
      toast.add({
        severity: 'info',
        summary: 'Information',
        detail: mainStore.serverResponseStatut.infoMessage,
        life: 5000,
      })
      mainStore.serverResponseStatut.infoMessage = ''
    }
  },
  { deep: true },
)

watch(isDarkMode, () => {
  if (isDarkMode.value) {
    primevue.changeTheme('lara-light-cyan', 'lara-dark-cyan', 'theme-link')
  } else {
    primevue.changeTheme('lara-dark-cyan', 'lara-light-cyan', 'theme-link')
  }
})

const deleteErrorIfNecessary = (toastMessage: any) => {
  const { message } = toastMessage
  if (message.severity === 'info') {
    mainStore.serverResponseStatut.infoMessage = ''
  } else if (message.severity === 'success') {
    mainStore.serverResponseStatut.successMessage = ''
  } else {
    const index = mainStore.serverResponseStatut.errorMessages.findIndex(
      (errorMessage) => !errorMessage.keepAfterDisplay && errorMessage.value === message.detail,
    )
    if (index !== -1) {
      mainStore.serverResponseStatut.errorMessages.splice(index, 1)
    }
  }
}

const logout = () => {
  authStore.logout()
}

const releaseFocus = () => {
  declarationStore.individualDeclaration.focusPanel = null
}
</script>

<template>
  <HeaderSection v-if="!loading && isMounted" />
  <div class="p-3 md:p-5 mt-8 padding-xxl">
    <div v-if="loading || !isMounted" class="flex justify-content-center">
      <ProgressSpinner aria-label="Chargement de la page" />
    </div>
    <RouterView v-else />
  </div>
  <Toast @close="deleteErrorIfNecessary" @lifeEnd="deleteErrorIfNecessary" />
  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-confirm-popup-content">
        <span class="p-confirm-popup-icon" :class="slotProps.message.icon" />
        <span v-sane-html="slotProps.message.message" class="p-confirm-popup-message"></span>
      </div>
    </template>
  </ConfirmPopup>
  <OnBoardingTour v-if="!loading && isMounted" />
  <Dialog
    :visible="showWarningSessionExpired"
    modal
    blockScroll
    :draggable="false"
    :closable="false"
    header="Session expirée"
    class="darker-mask"
  >
    <p>Pour continuer, veuillez vous reconnecter</p>
    <template #footer>
      <Button @click="logout" label="Se connecter" icon="pi pi-sign-in" rounded class="p-button-sm-mobile" />
    </template>
  </Dialog>
  <div v-if="displayOverlay" @click="releaseFocus()" class="overlay-mask" />
</template>
<style scoped>
.overlay-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--maskbg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
</style>
