<script setup lang="ts">
import { ref, computed } from 'vue'
import MainContentFixed from '../commons/MainContentFixed.vue'
import content from '@/assets/content/guide/guide'
import { useVgt } from 'vue-guided-tour'

const emit = defineEmits(['consultFaq', 'contactUs'])

const onboardingTour = useVgt()

const sections = ref(content)
const search = ref('')
const activeSection = ref('')
const menus = computed(() => {
  const links = sections.value.map((section) => ({ id: section.id, label: section.title }))

  const menus = [{ key: '0', label: 'Guide utilisateur', isTitle: true, items: links }]

  return menus
})

const filteredBySearch = computed(() =>
  sections.value.filter(
    (section) =>
      section.title.toLocaleLowerCase().includes(search.value) ||
      section.content.toLocaleLowerCase().includes(search.value),
  ),
)

const determineActiveSection = (event: any) => {
  menus.value.forEach((menu) => {
    menu.items.every((item, index) => {
      const section = document.getElementById(item.id)
      if (section && elemenIsInViewport(section, event.target, index)) {
        if (index < 12) {
          activeSection.value = item.id
          return false
        }
      }

      return true
    })
  })
}

const elemenIsInViewport = (element: HTMLElement, target: HTMLElement, index: number) => {
  const rect = element.getBoundingClientRect()

  let isInView = rect.top - 15 >= 0 && rect.left >= 0
  const scrollOffset = (target.scrollTop + target.clientHeight) / target.scrollHeight

  switch (index) {
    case 10:
      isInView = scrollOffset >= 0.945
      break
    case 11:
      isInView = scrollOffset >= 0.97
      break
    case 12:
      isInView = scrollOffset === 1
      break
  }

  return isInView
}

const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId)
  const mainContent = document.getElementById('main-content')

  if (section && mainContent) {
    mainContent.scrollTop = section.offsetTop - 180
  }
}
</script>
<template>
  <div class="grid pt-1">
    <div class="col-12 md:col-4 lg:col-3">
      <PanelMenu :model="menus" :expandedKeys="['0']" class="w-full">
        <template #item="{ item }">
          <a
            class="flex align-items-center p-1 px-3 no-underline text-color text-sm"
            :class="{
              'justify-content-center text-sm font-bold p-2 cursor-default': item.isTitle,
              'cursor-pointer': !item.isTitle,
              'in-progress': item.id === activeSection,
            }"
            @click="scrollToSection(item.id)"
          >
            <span>{{ item.label }}</span>
          </a>
        </template>
      </PanelMenu>
    </div>
    <div class="col-12 md:col-8 lg:col-9">
      <IconField class="mb-3">
        <InputIcon class="pi pi-search" />
        <InputText
          v-model="search"
          placeholder="Rechercher par mot clé"
          class="w-full"
          aria-label="Filter la FAQ par mot clé"
        />
      </IconField>
      <MainContentFixed @scrolling="determineActiveSection" id="main-content">
        <section v-for="section in filteredBySearch" :key="section.id" :id="section.id" class="w-full">
          <h2 class="mt-0">{{ section.title }}</h2>
          <Button
            v-if="section.id === 'navigation'"
            @click="onboardingTour.start(0)"
            label="Relancer la présentation"
            icon="pi pi-replay"
            severity="help"
            size="small"
            rounded
            outlined
            class="w-full"
          />
          <div v-sane-html="section.content" class="mb-6"></div>
        </section>
        <div class="flex flex-column align-items-center text-center gap-3 mt-4 mb-8 pb-8 w-full">
          <p class="m-0">Vous n'avez pas trouvé de réponses ? Vous pouvez :</p>
          <Button
            @click="emit('consultFaq')"
            label="Consulter la FAQ"
            icon="pi pi-question"
            severity="info"
            class="p-button-sm-mobile"
            rounded
          />
          <span>ou</span>
          <Button
            @click="emit('contactUs')"
            label="Nous contacter"
            icon="pi pi-comments"
            severity="help"
            class="p-button-sm-mobile mb-8"
            rounded
          />
        </div>
      </MainContentFixed>
    </div>
  </div>
</template>
<style scoped>
.active {
  color: var(--info-color);
}
</style>
