import {
  Subscriptions,
  Invoices,
  Companies,
  ErrorDsn,
  SupportMessagePayload,
  OrgPaymentColumnTab,
  OrgPaymentRowTab,
  SalaryRecap,
  Subscription,
  ConfirmPaymentResponse,
  BraintreePayload,
  MainError,
  PAYMENT_MODE,
} from '@/models/main.model'
import { teledsnAPI } from '@/services/teledsn-api'
import { defineStore } from 'pinia'
import { useCompanyStore } from './company'
import { useAuthStore } from './auth'
import { DECLARATION_TYPE, DeclarationDashboard, ReportDashboard } from '@/models/declaration.model'
import { Helper } from '@/models/main.helper'
import { useDeclarationStore } from './declaration'
import { router } from '@/routes'
import { Router } from '@/models/router.model'
import { useReportingStore } from './reporting'
import { useContractStore } from './contract'
import { useEmployeeStore } from './employee'

type State = {
  options: {
    zipCodes: string[]
  }
  routerSourcePage: Router
  subscription: Subscription & { paymentResponse: ConfirmPaymentResponse | null } & {
    source: DECLARATION_TYPE | null
  }
  dashboard: {
    declarations: { loading: boolean; results: DeclarationDashboard[] }
    reports: { loading: boolean; results: ReportDashboard[] }
    payments: {
      loading: boolean
      results: { columns: OrgPaymentColumnTab[]; rows: OrgPaymentRowTab[] }
      selectedYear: number
    }
    salaries: { loading: boolean; results: SalaryRecap[]; selectedYear: number }
  }
  subscriptions: { loading: boolean; results: Subscriptions[] }
  invoices: { loading: boolean; results: Invoices[] }
  companies: { loading: boolean; results: Companies[] }
  selectedCompany: Companies | null
  serverResponseStatut: {
    errorMessages: MainError[]
    infoMessage: string
    successMessage: string
    errorFields: string[]
  }
  loading: boolean
  subLoading: boolean
  forceSelectionCompany: boolean
  triggerOnBoardingTour: boolean
  hasRecentlySentDeclaration: boolean
  formHaveBeenModified: boolean
  showSavingWarning: boolean
  canceledRoute: string
}

export const useMainStore = defineStore('mainStore', {
  state: (): State => ({
    options: {
      zipCodes: [],
    },
    routerSourcePage: Router.HOME,
    subscription: {
      awaitingPaymentWithoutCollection: false,
      cbProvider: '',
      companyBillingInfos: {
        name: '',
        street: '',
        zipCode: '',
        locality: '',
        country: '',
      },
      invoiceView: {
        title: '',
        duration: 0,
        amountIncludingAllTaxes: 0,
        amountWithoutTax: 0,
        taxAmount: 0,
        taxRate: 0,
        totalCentsTtc: 0,
        paymentType: '',
        dateInvoice: '',
      },
      braintree: {
        clientToken: '',
      },
      paymentResponse: null,
      source: null,
    },
    dashboard: {
      declarations: { loading: false, results: [] },
      reports: { loading: false, results: [] },
      payments: { loading: false, results: { columns: [], rows: [] }, selectedYear: new Date().getFullYear() },
      salaries: { loading: false, results: [], selectedYear: new Date().getFullYear() },
    },
    subscriptions: { loading: false, results: [] },
    invoices: { loading: false, results: [] },
    companies: { loading: false, results: [] },
    selectedCompany: null,
    serverResponseStatut: {
      errorMessages: [],
      infoMessage: '',
      successMessage: '',
      errorFields: [],
    },
    loading: true,
    subLoading: false,
    forceSelectionCompany: false,
    triggerOnBoardingTour: false,
    hasRecentlySentDeclaration: false,
    formHaveBeenModified: false,
    showSavingWarning: false,
    canceledRoute: '',
  }),
  getters: {
    serverHaveReturnedError(): boolean {
      return this.serverResponseStatut.errorMessages && this.serverResponseStatut.errorMessages.length > 0
    },
    serverHaveReturnedSeriousError(): boolean {
      return this.getBlockingServorErrors.length > 0
    },
    isDeclarationSourcePage(): boolean {
      return [
        Router.REPORT_WORK_STOPPAGE,
        Router.DECLARATION_SUMMARY,
        Router.REPORT_ENDING_CONTRACT,
        Router.DECLARATION_SUMMARY + Router.INDIVIDUAL_DECLARATION,
        Router.DECLARATION_SUMMARY + Router.COMPANY,
        Router.DECLARATION_SUMMARY + Router.REGULARIZATION,
      ].includes(this.routerSourcePage)
    },
    getCompanyById() {
      return (id: number) => this.companies.results.find((company) => company.id === id) ?? null
    },
    getBlockingServorErrors(): string[] {
      const errors = [] as string[]

      this.serverResponseStatut.errorMessages.forEach((errorMessage) => {
        if (errorMessage.blocking) return errors.push(errorMessage.value)
      })

      return errors
    },
    getNonBlockingServorErrors(): string[] {
      const errors = [] as string[]

      this.serverResponseStatut.errorMessages.forEach((errorMessage) => {
        if (!errorMessage.blocking) return errors.push(errorMessage.value)
      })

      return errors
    },
    haveCompanies(): boolean {
      return this.companies && this.companies.results.length > 0
    },
    haveCanceledRoute(): boolean {
      return Boolean(this.canceledRoute)
    },
  },
  actions: {
    resetServerMessages() {
      this.serverResponseStatut.errorMessages = []
      this.serverResponseStatut.successMessage = ''
      this.serverResponseStatut.infoMessage = ''
      this.serverResponseStatut.errorFields = []
    },
    setServerResponseFromError(error: any, keepAfterDisplay?: boolean) {
      if (
        error &&
        (<ErrorDsn>error).response?.data?.functionalErrors &&
        (<ErrorDsn>error).response?.data?.functionalErrors.length > 0
      ) {
        for (const functionalError of (<ErrorDsn>error).response.data.functionalErrors) {
          if (functionalError.message) {
            this.addErrorIfNotAlreadyPresent({
              value: functionalError.message,
              blocking: functionalError.blocant,
              keepAfterDisplay,
            })
          }

          if (functionalError.propertyNames && functionalError.propertyNames.length > 0) {
            functionalError.propertyNames.forEach((propertyName) =>
              this.serverResponseStatut.errorFields.push(propertyName),
            )
          }
        }
      } else if (
        error &&
        (<ErrorDsn>error).response?.data?.fieldErrors &&
        (<ErrorDsn>error).response?.data?.fieldErrors.length > 0
      ) {
        for (const fieldError of (<ErrorDsn>error).response.data.fieldErrors) {
          this.addErrorIfNotAlreadyPresent({ value: fieldError.message, keepAfterDisplay })
        }
      } else if (error && (<ErrorDsn>error).response?.data?.message) {
        this.addErrorIfNotAlreadyPresent({
          value: (<ErrorDsn>error).response.data.message,
          keepAfterDisplay,
        })
      } else {
        this.addErrorIfNotAlreadyPresent({
          value: "Veuillez nous contacter si l'erreur persiste",
          keepAfterDisplay,
        })
      }
    },
    addErrorIfNotAlreadyPresent(error: MainError) {
      if (!this.serverResponseStatut.errorMessages.find((errorMessages) => errorMessages.value === error.value)) {
        this.serverResponseStatut.errorMessages.push(error)
      }
    },
    goBackToRouterSourcePage() {
      router.push(this.routerSourcePage)
    },
    async getSubscriptions(userId: string) {
      try {
        this.subscriptions.loading = true
        const response = await teledsnAPI.getUserSubscriptions(userId)
        this.subscriptions.results = Helper.storingSubscriptionsFromAPI(response)
      } catch {
        this.addErrorIfNotAlreadyPresent({ value: 'Erreur sur la récupération des abonnements' })
      } finally {
        this.subscriptions.loading = false
      }
    },
    async getOrgPaymentsRecap(year: number) {
      const companyStore = useCompanyStore()

      try {
        this.dashboard.payments.loading = true

        if (companyStore.company.id) {
          const response = await teledsnAPI.getOrgPaymentsRecap(year, companyStore.company.id)

          this.dashboard.payments.results = response
          this.dashboard.payments.results.rows = this.dashboard.payments.results.rows
            .sort((a, b) => Number(new Date(a.label)) - Number(new Date(b.label)))
            .map((row) => {
              return {
                ...row,
                label: row.label.slice(3),
              }
            })
        }
      } catch {
        this.addErrorIfNotAlreadyPresent({
          value: 'Erreur sur la récupération du récapitulatif des paiements versés aux organismes',
        })
      } finally {
        this.dashboard.payments.loading = false
      }
    },
    async getSalariesRecap(year: number) {
      try {
        this.dashboard.salaries.loading = true
        const companyStore = useCompanyStore()

        if (companyStore.company.id) {
          const response = await teledsnAPI.getSalariesRecap(year, companyStore.company.id)

          this.dashboard.salaries.results = response.sort((a, b) => {
            if (a.employee.lastName === b.employee.lastName) return 0
            if (!a.employee.firstName) return 1
            if (!b.employee.firstName) return -1
            if (a.employee.lastName < b.employee.lastName) return -1
            if (a.employee.lastName > b.employee.lastName) return 1
            return 0
          })
        }
      } catch {
        this.addErrorIfNotAlreadyPresent({
          value: 'Erreur sur la récupération du récapitulatif des versements salariés',
        })
      } finally {
        this.dashboard.salaries.loading = false
      }
    },
    async getInvoices(userId: number) {
      try {
        this.invoices.loading = true
        const response = await teledsnAPI.getUserInvoices(userId)
        this.invoices.results = Helper.storingInvoicesFromAPI(response)
      } catch {
        this.addErrorIfNotAlreadyPresent({ value: 'Erreur sur la récupération des factures' })
      } finally {
        this.invoices.loading = false
      }
    },
    async getCompanies() {
      const authStore = useAuthStore()
      try {
        this.companies.loading = true
        const response = await teledsnAPI.getUserCompanies(authStore.userInfos.userId)
        this.companies.results = Helper.storingCompaniesFromAPI(response)

        /**
         * Si l'utilisateur n'a aucune entreprise
         * on vide la plupart des données
         */
        if (this.companies.results.length === 0) {
          this.dashboard = {
            declarations: { loading: false, results: [] },
            reports: { loading: false, results: [] },
            payments: { loading: false, results: { columns: [], rows: [] }, selectedYear: new Date().getFullYear() },
            salaries: { loading: false, results: [], selectedYear: new Date().getFullYear() },
          }
          useDeclarationStore().$reset()
          useReportingStore().$reset()
          useEmployeeStore().$reset()
          useContractStore().$reset()
        }
      } catch {
        this.addErrorIfNotAlreadyPresent({ value: 'Erreur sur la récupération des entreprises' })
      } finally {
        this.companies.loading = false
      }
    },
    getInvoicePDF(invoiceId: number) {
      try {
        this.invoices.loading = true
        window.open(`${import.meta.env.VITE_TELEDSN_BACKEND_URL}/facture-TELEDSN-${invoiceId}.pdf`, '_blank')
      } catch {
        this.addErrorIfNotAlreadyPresent({ value: 'Erreur sur le débrancement vers la facture PDF' })
      } finally {
        this.invoices.loading = false
      }
    },
    async deleteCompany(companyId: number) {
      const companyStore = useCompanyStore()
      try {
        this.companies.loading = true

        await teledsnAPI.deleteCompany(companyId)

        if (companyStore.company.id === companyId) {
          companyStore.$reset()
        }
        await this.getCompanies()
        this.serverResponseStatut.successMessage = "L'entreprise a bien été supprimée"
      } catch (error) {
        this.setServerResponseFromError(error)
      } finally {
        this.companies.loading = false
      }
    },
    async getZipCodes() {
      try {
        //Ne pas recharger la liste si elle est déjà présente
        if (!this.options.zipCodes || !this.options.zipCodes.length) {
          const response = await teledsnAPI.getZipCodes()
          this.options.zipCodes = response
        }
      } catch (error) {
        this.addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des codes postaux.',
        })
      }
    },
    async getCitiesSuggestions(zipCode: string) {
      try {
        const response = await teledsnAPI.getCitiesSuggestions(zipCode)
        return response
      } catch (error) {
        this.addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des options des villes.',
        })
      }
    },
    async sendSupportMessage(payload: SupportMessagePayload) {
      try {
        this.subLoading = true
        const response = await teledsnAPI.sendSupportMessage(payload)
        this.serverResponseStatut.successMessage = response.message
      } catch (error) {
        this.setServerResponseFromError(error)
      } finally {
        this.subLoading = false
      }
    },
    async getDeclarations() {
      const companyStore = useCompanyStore()
      try {
        this.dashboard.declarations.loading = true
        if (companyStore.company.id) {
          const response = await teledsnAPI.getDeclarations(companyStore.company.id)
          this.dashboard.declarations.results = Helper.transformDeclarationsToDeclarationsDashboard(response)
        }
      } catch (error) {
        this.addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des déclarations.',
        })
      } finally {
        this.dashboard.declarations.loading = false
      }
    },
    async confirmPayment(mode: PAYMENT_MODE, braintreeNonce?: BraintreePayload) {
      const companyStore = useCompanyStore()
      const declarationStore = useDeclarationStore()
      this.subLoading = true
      try {
        this.subscription.invoiceView.paymentType = mode
        const paymentParams = {
          braintreeNonce: braintreeNonce || '',
          payplugToken: '',
        }
        const payload = Helper.constructConfirmPaymentPayload(
          this.subscription,
          companyStore.company.generalInformations.locality,
          paymentParams,
        )
        let declarationId = null
        if (this.isDeclarationSourcePage) {
          declarationId = declarationStore.declaration.id
        }
        const params = {
          declarationId,
          nature: this.subscription.source!,
        }
        if (companyStore.company.id) {
          const response = await teledsnAPI.confirmPayment(companyStore.company.id, payload, params)
          this.subscription.paymentResponse = response
        }
      } catch (error) {
        this.addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la confirmation du paiement',
        })
      } finally {
        if (mode === PAYMENT_MODE.CREDIT_CARD) await this.getCompanies()
        this.subLoading = false
      }
    },
    async getSubscriptionInfos() {
      const companyStore = useCompanyStore()
      try {
        this.subLoading = true
        if (companyStore.company.id) {
          const response = await teledsnAPI.getSubscriptionInfos(companyStore.company.id)
          this.subscription = {
            ...Helper.storingSubscriptionInfosFromAPI(response),
            paymentResponse: null,
            source: this.subscription.source,
          }
        }
      } catch (err) {
        this.addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de la récupération des informations d'abonnement.",
        })
      } finally {
        this.subLoading = false
      }
    },
    async getReports() {
      const companyStore = useCompanyStore()
      try {
        this.dashboard.reports.loading = true
        if (companyStore.company.id) {
          const response = await teledsnAPI.getDeclarations(companyStore.company.id, true)
          this.dashboard.reports.results = Helper.transformDeclarationsToReportsDashboard(response)
        }
      } catch (error) {
        this.addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des signalements.',
        })
      } finally {
        this.dashboard.reports.loading = false
      }
    },
    async displayOnBoardingTour() {
      try {
        const response = await teledsnAPI.displayOnBoardingTour()
        this.triggerOnBoardingTour = response.result
      } catch (error) {
        this.addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de la récupération de l'affichage de l'onboarding tour.",
        })
      }
    },
    async recordLastStepOnBoardingTour(step: string) {
      try {
        await teledsnAPI.recordLastStepOnBoardingTour(step)
      } catch (error) {
        this.addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de l'enregistrement de la dernière étape de l'onboarding tour.",
        })
      }
    },
  },
  persist: true,
})
