<script setup lang="ts">
import { computed, ref } from 'vue'
import { useCompanyStore } from '@/stores/company'
import { validateEmailFormat } from '@/utils'
import { useMainStore } from '@/stores/main'
import ServorError from '@/components/commons/ServorError.vue'

const emit = defineEmits(['close'])

const mainStore = useMainStore()
const companyStore = useCompanyStore()

const contact = ref(companyStore.company.contact)
const emailObject = ref('')
const formSubmitted = ref(false)
const emailObjectOptions = ref([
  "Demande d'assistance",
  "Demande d'informations",
  'Demander la suppression de mon compte utilisateur',
  'Demander la suppression de mon entreprise',
  'Partenariat/Affiliation',
  'Signaler une erreur ou un problème',
])
const messageToSend = ref('')

const companyId = computed(() => companyStore.company.id)
const emptyField = computed(() => !messageToSend.value || !emailObject.value || !contact.value)
const checkEmailFormat = computed(() => validateEmailFormat(contact.value.email))
const loading = computed(() => mainStore.subLoading)
const additionalMessages = computed(() => {
  const messages = []

  if (emptyField.value) messages.push('Un ou plusieurs champs sont manquants')
  if (!checkEmailFormat.value) messages.push('Votre adresse email doit être une adresse mail valide')

  return messages
})

const sendMessage = async () => {
  formSubmitted.value = true
  if (!emptyField.value && checkEmailFormat.value) {
    await mainStore.sendSupportMessage({
      feedbackEmail: contact.value.email,
      feedbackObjet: emailObject.value,
      feedbackRequete: messageToSend.value,
      feedbackTelephone: contact.value.phoneNumber,
      entrepriseId: companyId.value!,
    })
    emit('close')
  }
}
</script>

<template>
  <div class="flex flex-column gap-5">
    <p>
      Une question ? Envoyez-nous un message à l'aide du formulaire ci-dessous ou appelez-nous au :
      <br /><b>09 72 46 44 20</b> (numéro non surtaxé)
    </p>
    <FloatLabel class="w-full">
      <InputText
        v-model="contact.email"
        id="email"
        placeholder="Votre adresse email"
        :disabled="loading"
        :class="{ 'p-invalid': (!checkEmailFormat || !contact.email) && formSubmitted }"
      />
      <label for="email">Votre adresse email</label>
    </FloatLabel>
    <FloatLabel class="w-full">
      <InputMask v-model="contact.phoneNumber" id="basic" mask="9999999999" :disabled="loading" />
      <label for="phoneNumber">Votre numéro de téléphone</label>
    </FloatLabel>
    <FloatLabel class="w-full">
      <Dropdown
        v-model="emailObject"
        id="emailObject"
        :options="emailObjectOptions"
        placeholder="Sélectionnez l'objet de votre message"
        :disabled="loading"
        :class="{ 'p-invalid': !emailObject && formSubmitted }"
      />
      <label for="emailObject">Objet du message</label>
    </FloatLabel>
    <FloatLabel class="w-full">
      <TextAreaPrimevue
        v-model="messageToSend"
        rows="5"
        cols="30"
        :disabled="loading"
        :class="{ 'p-invalid': !messageToSend && formSubmitted }"
      />
      <label>Message</label>
    </FloatLabel>
    <ServorError
      v-if="formSubmitted && (emptyField || mainStore.serverHaveReturnedError || !checkEmailFormat)"
      :additionalMessages="additionalMessages"
    />
    <Button @click="sendMessage" label="Envoyer" icon="pi pi-send" rounded :loading="loading" />
  </div>
</template>
