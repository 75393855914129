export type UserGuide = {
  id: string
  title: string
  content: string
}

const content: UserGuide[] = [
  {
    id: 'introduction',
    title: 'Introduction',
    content: `<p>La nouvelle interface Teledsn propose une interface plus moderne et plus aérée que l’ancienne interface,
        tout en reprenant les principes généraux de l’ancienne interface. Le but est de simplifier la navigation avec moins de clics,
        en présentant l’information utile de manière regroupée au bon endroit et au bon moment.</p>
        <p>C’est aussi l’opportunité pour nous de vous proposer de nouvelles fonctionnalités, qui viendront très prochainement enrichir l’application.</p>    
        <p><u class="text-purple-500">Astuce :</u> Positionner la souris sur un élément (sans cliquer dessus) affiche un court
            texte explicatif.</p>
        <img src="/images/guide/tooltip_astuce.jpg" class="w-full" />
        `,
  },
  {
    id: 'navigation',
    title: 'La navigation',
    content: `<p>La navigation s’effectue via une barre de navigation en haut permettant d’accéder rapidement aux
        principales fonctionnalités</p>
        <img src="/images/guide/navigation_steps.jpg" class="w-full" />
        <ol>
            <li class="mb-1">Cliquer sur le logo de l’application vous ramènera à la page d'accueil (Mes déclarations).</li>
            <li class="mb-1">Le bouton « Déclarations » vous permet d’accéder à votre tableau de bord présentation la liste de vos déclarations, signalement et des états de synthèse.</li>
            <li class="mb-1">Le nom de l’entreprise s’affiche dans le menu déroulant et vous pouvez basculer d’une entreprise à l’autre si vous avez déclaré plusieurs entreprises.</li>
            <li class="mb-1">L'icône <i class="pi pi-pencil"></i> vous permet d’éditer la fiche entreprise.</li>
            <li class="mb-1">L'icône <i class="pi pi-plus"></i> est un raccourci vous permettant de créer une nouvelle entreprise, un nouveau salarié, ou un nouveau contrat.</li>
            <li class="mb-1">L'icône <i class="pi pi-users"></i> vous permet d’accéder à la liste des vos salariés.</li>
            <li class="mb-1">Le bouton « Besoin d’aide » vous permet d’accéder aux fonctionnalités d’assistance, tel que ce guide utilisateur, une FAQ, ainsi qu’à notre formulaire de contact.</li>
            <li class="mb-1">Le bouton « Mon compte » vous permet d’accéder aux différents éléments liés à votre compte : vos identifiants, vos abonnements, vos factures, vos entreprises, ainsi qu’un bouton de déconnexion. Vous y retrouverez également les paramètres de l’application (exemple : thème clair / sombre).</li>    
        </ol>
        `,
  },
  {
    id: 'fil_ariane',
    title: 'Le fil d’ariane',
    content: `<img src="/images/guide/fil_ariane.jpg" class="w-full" />
        <p class="mt-1">Chaque écran s’affiche avec un fil d’ariane en haut qui vous permet de vous situer dans la navigation et
        où vous vous trouvez dans l’application. Tous les éléments sont cliquables afin de remonter facilement l’arborescence.</p>`,
  },
  {
    id: 'tableau_de_bord',
    title: 'Le tableau de bord',
    content: `<img src="/images/guide/tableau_de_bord.jpg" class="w-full" />
        <p>Le tableau de bord est l’écran principal qui regroupe les données créées régulièrement, à la fois les DSN et les signalements. Quelques états de synthèse sont également disponibles.</p>`,
  },
  {
    id: 'fiche_entreprise',
    title: 'Paramétrer son entreprise',
    content: `<img src="/images/guide/fiche_entreprise.jpg" class="w-full" />
        <p>Paramétrer son entreprise s’effectue en 5 étapes :</p>
        <ol>
            <li><b>Informations générales</b> permet de renseigner les données administratives de l’entreprise (SIREN, convention collective etc) ainsi que l’adresse.</li>
            <li><b>Contact</b> permet de renseigner le contact principal de l’entreprise. Cette information est transmise dans les données DSN.</li>
            <li><b>Organismes Sociaux</b> permet de déclarer tous les organismes sociaux auxquels l’entreprise est affiliée pour payer des cotisations</li>
            <li><b>Fiscalité</b> permet de renseigner différentes taxes auxquelles l’entreprise est assujettie</li>
            <li><b>Taux accident du travail</b> permet de renseigner le code risque et le/les taux AT de l’entreprise</li>
        </ol>
        <p>Le passage d’un écran à l’autre s’effectue par les boutons précédents / suivants et vos données saisies sont sauvegardées d’une étape à l’autre. En édition il est également possible d’accéder directement à une étape via le bandeau avec les numéros.</p>
        <p><u class="text-purple-500">Astuce :</u> en cas de doute, n'hésitez à consulter l'aide présente sur certains champs, via ces icônes <i class="pi pi-question-circle"></i>.</p>
        `,
  },
  {
    id: 'salaries_et_contrats',
    title: 'Gestion des salariés et de leurs contrats',
    content: `<p>Vous accédez à la gestion des salariés et de leurs contrats à partir de l’icône <i class="pi pi-users"></i> située dans la barre de navigation en haut de l’écran.</p>
        <img src="/images/guide/salaries_et_contrats.jpg" class="w-full" />
        <p>Chaque ligne dans le tableau représente un salarié, le bouton « Modifier les informations »  vous permet d’accéder aux détails du salarié tandis que que le bouton « Ajouter un salarié »  
        vous permet de créer facilement un nouveau salarié. Le bouton « Contrats <i class="pi pi-eye"></i> » en début de ligne permet d’afficher immédiatement en dessous le(s) contrat(s) du salarié.</p>`,
  },
  {
    id: 'fiche_salarie',
    title: 'Éditer les informations individuelles d’un salarié',
    content: `<img src="/images/guide/fiche_salarie.jpg" class="w-full" />
        <p>L’état civil et l’adresse du salarié s’éditent sur un même écran et la liste des contrats déjà existants pour le salarié apparaît sur la partie gauche, ce qui permet d’accéder en un simple clic aux données d’un contrat.</p>`,
  },
  {
    id: 'fiche_contrat',
    title: 'Éditer les données d’un contrat de travail',
    content: `<img src="/images/guide/fiche_contrat.jpg" class="w-full" />
        <p>Les informations d’un contrat de travail sont réparties sur 5 écrans et regroupées par catégories :</p>
        <ul>
            <li>Informations générales (type de contrat, libellé, régime…)</li>
            <li>Statut (dispositifs d’aide publique …)</li>
            <li>Catégorie socioprofessionnelle</li>
            <li>Temps de travail</li>
            <li>Informations complémentaires</li>
        </ul>`,
  },
  {
    id: 'declaration',
    title: 'Créer une déclaration sociale nominative (DSN)',
    content: `<p>Une DSN se compose de plusieurs formulaires :</p>
        <ul>
            <li>Des DSN individuelles (1 DSN par contrat actif de chaque salarié)</li>
            <li>D’éventuelles régularisations pour les périodes précédentes</li>
            <li>La DSN entreprise qui agrège et déclare les cotisations entreprises</li>
        </ul>
        <h4>Initialiser une DSN mensuelle</h4>
        <img src="/images/guide/tableau_de_bord_creer_dsn.jpg" class="w-full" />
        <p>La création d’une DSN mensuelle s’effectue depuis l'onglet « Mes déclarations » du tableau de bord en cliquant sur le bouton « Effectuer une DSN mensuelle »</p>
        <p>Une fenêtre s’ouvre afin de sélectionner la période que vous souhaitez déclarer, en général le mois précédent.</p>
        <img src="/images/guide/modal_creer_dsn_steps.jpg" class="w-full" />
        <h4>Résumé et statut d’une DSN mensuelle</h4>
        <p>Une fois la déclaration initialisée, vous accédez à un écran de résumé vous présentant les DSN individuelles de chaque salarié à compléter.
        Une fois ces DSN individuelles complétées, vous pouvez ensuite compléter la DSN entreprise et éventuellement ajouter des régularisations une fois les
        DSN individuelles complétées</p>
        <img src="/images/guide/summary_dsn.jpg" class="w-full" />
        <p><u class="text-purple-500">Bon à savoir :</u> la possibilité de compléter la DSN entreprise ne vous sera proposée qu’une fois les DSN mensuelles
        complétées mais vous pourrez bien-sûr retourner sur chaque DSN individuelle si vous devez y apporter des modifications.</p>
        <h4>Saisie d’une DSN individuelle</h4>
        <p>Une DSN individuelle se divise en plusieurs blocs d’informations qu’il est préférable de remplir de haut en bas mais vous pouvez naviguer d’un
        bloc à l’autre en scrollant de haut en bas ou en utilisant le menu de gauche qui vous positionne automatiquement sur le bon bloc. Lorsque vous prenez
        le focus sur un bloc, celui-ci est mis en évidence.</p>
        <img src="/images/guide/dsn_indiv.jpg" class="w-full" />
        <p>Les calculs se font automatiquement, la couleur de texte (noir ou gris) permet de reconnaitre qu’une cellule est édtiable ou non. Même si certains
        champs sont automatiquement calculés, il peut être possible d’en modifier la valeur si la cellule est éditable</p>
        <h4>Saisie d’une régularisation</h4>
        <img src="/images/guide/regul.jpg" class="w-full" />
        <p>Une régularisation permet de corriger des données d’une DSN d’une période précédente. Il peut être nécessaire d’effectuer des régularisations lorsque
        vous recevez des courriers ou notifications de la part des organismes sociaux lorsqu’ils vous informent d’erreurs ou d’écarts avec les données qu’ils attendent.
        Une régularisation s’effectue en saisissant l’écart par rapport à une valeur initialement déclarée.</p>
        <p><u class="text-purple-500">Exemple :</u> si un organisme vous informe que vous avez déclaré 100 dans une cotisation alors que vous auriez dû saisir 120,
        il faut alors saisir la valeur 20 dans la régularisation correspondante. L’ajout d’une régularisation s’effectue depuis l’écran de résumé de la DSN du mois courant que vous déclarez.</p>
        <h4>Saisie d’une DSN Entreprise</h4>
        <img src="/images/guide/dsn_entreprise.jpg" class="w-full" />
        <p>Vous retrouverez vos données saisies dans les DSN Individuelles, agrégées par bordereau d’organisme social (exemple URSSAF, AGIRC-ARRCO, DGFIP). Vérifier et corriger les éléments si besoin.</p>
        <h4>Envoyer une DSN</h4>
        <img src="/images/guide/envoyer_dsn.jpg" class="w-full" />
        <p>Une fois votre DSN complétée, vous pourrez accéder à la synthèse puis à l’envoi en cliquant sur le bouton « Synthèse et envoi »</p>
        <p><u class="text-purple-500">Bon à savoir :</u> l’envoi d’une DSN nécessite un abonnement actif pour l’entreprise concernée.</p>
        `,
  },
  {
    id: 'signalement',
    title: 'Créer un signalement',
    content: `<p>Il y a 3 types de signalement disponibles :</p>
        <ul>
            <li>Arrêt de travail</li>
            <li>Reprise arrêt de travail</li>
            <li>Fin de contrat de travail unique</li>
        </ul>
        <h4>Initialiser un signalement</h4>
        <img src="/images/guide/init_signalement.jpg" class="w-full" />
        <p>La création d’un signalement s’effectue depuis l’onglet « Mes signalements » du tableau de bord en cliquant sur le bouton « Effectuer un signalement »</p>
        <h4>Signaler un arrêt de travail</h4>
        <img src="/images/guide/signalement_arret_travail.jpg" class="w-full" />
        <p><i>En cours de rédaction</i></p>
        <h4>Signaler une reprise suite à un arrêt de travail</h4>
        <img src="/images/guide/signalement_reprise.jpg" class="w-full" />
        <p><i>En cours de rédaction</i></p>
        <h4>Signaler une fin de contrat de travail</h4>
        <img src="/images/guide/signalement_fin_contrat_travail.jpg" class="w-full" />
        <p><i>En cours de rédaction</i></p>
        `,
  },
]

export default content
